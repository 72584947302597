import STTerm from './STTerm'
import '@fontsource/fira-code'
import { useState, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'

function App() {
  const [sessionId, setSessionId] = useState('')

  useEffect(() => {
    const uuid = uuidv4()
    setSessionId(uuid)
  }, [])

  return (
    <div className="h-screen">
      <STTerm sessionId={sessionId} />
    </div>
  )
}

export default App
