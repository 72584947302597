import * as amplitude from '@amplitude/analytics-browser'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import App from './App.tsx'
import './index.css'
import * as Sentry from '@sentry/react'

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [],
  tracePropagationTargets: ['localhost', import.meta.env.VITE_BACKEND_URL],
  environment: import.meta.env.VITE_ENV,
})

if (import.meta.env.VITE_ENV === 'prod') {
  amplitude.init(import.meta.env.VITE_AMPLITUDE_API_KEY, { autocapture: true })
}

createRoot(document.getElementById('root')!).render(
  <Sentry.ErrorBoundary fallback={<div>Error</div>}>
    <StrictMode>
      <App />
    </StrictMode>
  </Sentry.ErrorBoundary>
)
