import { track } from '@amplitude/analytics-browser'
import 'github-markdown-css/github-markdown.css'
import React, { useCallback, useEffect, useState } from 'react'
import { TypeAnimation } from 'react-type-animation'
import getCaretCoordinates from 'textarea-caret'
import LoadingSpinner from './LoadingSpinner'
import { MemoizedReactMarkdown } from './markdown'

interface PromptProps {
  sessionId: string
  splashLoading: boolean
}

const Prompt: React.FC<PromptProps> = ({ sessionId, splashLoading }) => {
  const [inputValue, setInputValue] = useState<string>('')
  const [query, setQuery] = useState<string>('')

  const [log, setLog] = useState<string[]>([])

  const [loading, setLoading] = useState<boolean>(false)
  const [firstMsg, setFirstMsg] = useState<boolean>(true)

  const [streamedResponse, setStreamedResponse] = useState<string>('')

  const promptText = 'user@supertrace: ~ $ '

  const [inputEle, setInputEle] = useState<HTMLTextAreaElement | null>(null)
  const [caretCoords, setCaretCoords] = useState<{
    x: number
    y: number
  } | null>(null)
  const inputRef = useCallback(
    (inputElement: HTMLTextAreaElement | null) => {
      if (inputElement) {
        setInputEle(inputElement)
        inputElement.focus()
        if (inputValue === '') {
          inputElement.setSelectionRange(promptText.length, promptText.length)
        }
      }
    },
    [inputValue]
  )

  const [promptEnabled, setPromptEnabled] = useState<boolean>(true)

  const handleInputClick = useCallback(
    (e: React.MouseEvent<HTMLTextAreaElement>) => {
      e.preventDefault()
      const textarea = e.currentTarget
      const selectionStart = Math.max(
        textarea.selectionStart,
        promptText.length
      )
      const selectionEnd = Math.max(textarea.selectionEnd, promptText.length)
      textarea.setSelectionRange(selectionStart, selectionEnd)
    },
    [promptText]
  )

  const handleContainerClick = useCallback(() => {
    if (inputEle) {
      inputEle.focus()
    }
  }, [inputEle])

  const handleDragOver = useCallback(
    (e: React.DragEvent<HTMLTextAreaElement>) => {
      e.preventDefault()
    },
    []
  )

  const handleDrop = useCallback((e: React.DragEvent<HTMLTextAreaElement>) => {
    e.preventDefault()
  }, [])

  const updateCaretPosition = (textarea: HTMLTextAreaElement) => {
    const coords = getCaretCoordinates(textarea, textarea.selectionStart)
    setCaretCoords({ x: coords.left, y: coords.top })
  }

  useEffect(() => {
    if (inputEle) {
      updateCaretPosition(inputEle)
    }
  }, [inputEle, inputValue])

  useEffect(() => {
    const fetchStreamedResponse = async () => {
      try {
        if (import.meta.env.VITE_ENV === 'prod') {
          track('query', {
            query: query,
            session_id: sessionId,
          })
        }
        const encodedQuery = encodeURIComponent(query)
        const response = await fetch(
          `${import.meta.env.VITE_BACKEND_URL}/chat?id=${sessionId}&q=${encodedQuery}`
        )
        if (response.status === 429) {
          setStreamedResponse('Too many queries. Please try again later.')
          setPromptEnabled(false)
          setLoading(false)
          return
        }
        if (!response.body) throw new Error('Readable stream not supported')

        const reader = response.body.getReader()
        const decoder = new TextDecoder()
        let done = false

        while (!done) {
          const { value, done: streamDone } = await reader.read()
          done = streamDone
          const chunk = decoder.decode(value, { stream: true })
          setStreamedResponse((prev) => prev + chunk)
        }

        if (response.headers.get('x-ratelimit-remaining') === '0') {
          setPromptEnabled(false)
          setLoading(false)
          return
        } else {
          setLoading(false)
        }
      } catch (error) {
        console.error('Error reading stream:', error)
      }
    }

    if (loading) {
      fetchStreamedResponse()
    }

    if (streamedResponse !== '') {
      setLog((prevLog) => [...prevLog, streamedResponse])
      setStreamedResponse('')
    }

    const handleClick = () => {
      if (inputEle) {
        inputEle.focus()
        updateCaretPosition(inputEle)
      }
    }

    const handleRightClick = (event: MouseEvent) => {
      event.preventDefault()
      handleClick()
    }

    document.addEventListener('click', handleClick)
    document.addEventListener('contextmenu', handleRightClick)
    return () => {
      document.removeEventListener('click', handleClick)
      document.removeEventListener('contextmenu', handleRightClick)
    }
  }, [inputValue, inputEle])

  useEffect(() => {
    if (log.length > 0) {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: 'smooth',
      })
    }
  }, [log, streamedResponse])

  const handleInputKeyDown = async (
    e: React.KeyboardEvent<HTMLTextAreaElement>
  ) => {
    if ((e.ctrlKey || e.metaKey) && e.key === 'a') {
      e.preventDefault()
      e.currentTarget.setSelectionRange(
        promptText.length,
        promptText.length + inputValue.length
      )
    }
    setFirstMsg(false)
    if (inputValue === '') {
      e.currentTarget.setSelectionRange(promptText.length, promptText.length)
    }
    if (e.key === 'Enter') {
      e.preventDefault()
      if (streamedResponse !== '') {
        setLog((prevLog) => [...prevLog, streamedResponse])
        setStreamedResponse('')
      }
      setLog((prevLog) => [...prevLog, `${promptText} ${inputValue}`])

      if (inputValue !== '') {
        setStreamedResponse('')
        setLoading(true)

        // move input over to query and clear
        setQuery(inputValue)
        setInputValue('')
        return
      }
    }
    if (inputValue === '') {
      if (e.key === 'Backspace') {
        e.preventDefault()
      }
      e.currentTarget.setSelectionRange(promptText.length, promptText.length)
    }
  }
  return (
    <div className="flex flex-col h-full">
      <div>
        {/* Render the log (previous queries and answers) */}
        {log.map((entry, index) => (
          <div key={index}>
            {entry.startsWith('user@supertrace: ~ $') ? (
              <p>
                {promptText.slice(0, promptText.length - 2)}
                <span className="text-[#78FF57] font-bold">$</span>
                {entry.slice(promptText.length)}
              </p>
            ) : (
              <div className="markdown-body">
                <MemoizedReactMarkdown
                  components={{
                    a({ children, ...props }) {
                      const url = new URL(props.href ?? '', location.href)
                      if (url.origin !== location.origin) {
                        props.target = '_blank'
                        props.rel = 'noopener noreferrer'
                      }

                      return <a {...props}>{children}</a>
                    },
                  }}
                >
                  {entry}
                </MemoizedReactMarkdown>
              </div>
            )}
            <br />
          </div>
        ))}
        {streamedResponse !== '' ? (
          <div>
            <div className="markdown-body">
              <MemoizedReactMarkdown
                components={{
                  a({ children, ...props }) {
                    const url = new URL(props.href ?? '', location.href)
                    if (url.origin !== location.origin) {
                      props.target = '_blank'
                      props.rel = 'noopener noreferrer'
                    }

                    return <a {...props}>{children}</a>
                  },
                }}
              >
                {streamedResponse}
              </MemoizedReactMarkdown>
            </div>
            <br />
          </div>
        ) : loading ? (
          <LoadingSpinner />
        ) : (
          <></>
        )}
      </div>
      {loading || splashLoading || !promptEnabled ? (
        <></>
      ) : (
        <div className="flex flex-row h-full items-center">
          <div
            className="relative w-full h-full"
            onClick={handleContainerClick}
          >
            {firstMsg && inputValue === '' ? (
              <div className="relative">
                <span className="absolute inset-0 z-10">
                  <TypeAnimation
                    preRenderFirstString={true}
                    sequence={[
                      'user@supertrace: ~ $',
                      'user@supertrace: ~ $ tldr?',
                      2000,
                      'user@supertrace: ~ $ how does supertrace work?',
                      2000,
                      'user@supertrace: ~ $ team?',
                      2000,
                      "user@supertrace: ~ $ explain supertrace like i'm a 5 year old",
                      2000,
                      'user@supertrace: ~ $ contact info',
                      2000,
                      "user@supertrace: ~ $ who are supertrace's customers?",
                      2000,
                      'user@supertrace: ~ $ pricing plan?',
                      2000,
                      'user@supertrace: ~ $ if supertrace were an ice cream flavor, what would it taste like?',
                      2000,
                      'user@supertrace: ~ $ write a haiku about supertrace',
                      2000,
                      "user@supertrace: ~ $ how would a pirate describe st's investors?",
                      2000,
                    ]}
                    style={{ color: '#737373', display: 'block' }}
                    className="custom-type-animation-cursor"
                    wrapper="span"
                    speed={80}
                    repeat={Infinity}
                  />
                </span>
                <div className="absolute inset-0 z-20">
                  <p className="whitespace-nowrap bg-black w-max">
                    user@supertrace: ~{' '}
                    <span className="text-[#78FF57] font-bold bg-black">$</span>{' '}
                  </p>
                </div>
              </div>
            ) : null}
            <div className="absolute inset-0 pointer-events-none">
              <p className="whitespace-nowrap bg-black w-max">
                user@supertrace: ~{' '}
                <span className="text-[#78FF57] font-bold bg-black">$</span>
                &nbsp;
              </p>
            </div>
            <textarea
              className={`font-mono select-none caret-transparent outline-none w-full resize-none min-h-[0.5em] bg-transparent ${firstMsg && inputValue === '' ? 'caret-transparent' : ''}`}
              ref={inputRef}
              value={promptText + inputValue}
              onChange={(e) => {
                const newValue = e.target.value
                if (!newValue.startsWith(promptText)) {
                  e.preventDefault()
                } else {
                  setInputValue(newValue.slice(promptText.length))
                }
                e.target.style.height = 'auto'
                e.target.style.height = `${e.target.scrollHeight}px`
              }}
              onKeyDown={handleInputKeyDown}
              onClick={handleInputClick}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
              enterKeyHint="send"
              spellCheck="false"
            />
            <div
              className="absolute w-[1ch] h-[1.2em] bg-white"
              style={{
                left: `${caretCoords?.x}px`,
                top: `${caretCoords?.y}px`,
                opacity:
                  caretCoords && (!firstMsg || inputValue !== '') ? 1 : 0,
                animation: 'cursor 1.1s infinite step-start',
              }}
            />
          </div>
        </div>
      )}
    </div>
  )
}

interface STTermProps {
  sessionId: string
}

const STTerm: React.FC<STTermProps> = ({ sessionId }) => {
  const [splashLoading, setSplashLoading] = useState<boolean>(true)

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
  const intro_string =
    '> Supertrace is an applied AI research lab for networks. \n' +
    '> We are a deeply technical team of alumni from Verizon, Nvidia, Google and Microsoft building AI agents for network operations teams. \n' +
    '> Ask anything below.\n' +
    `> (${isMobile ? 'just tap to type' : 'just type'}).`

  return (
    <div className="p-4">
      <TypeAnimation
        cursor={false}
        className="custom-type-animation-cursor"
        sequence={[
          (el: HTMLElement | null) =>
            el?.classList.add('custom-type-animation-cursor'),
          intro_string,
          1200,
          (el: HTMLElement | null) =>
            el?.classList.remove('custom-type-animation-cursor'),
          () => setSplashLoading(false),
        ]}
        style={{ color: '#f5f5f5', display: 'block', whiteSpace: 'pre-line' }}
        wrapper="span"
        speed={88}
        repeat={0}
      />
      <style jsx={undefined}>{`
        .custom-type-animation-cursor::after {
          content: '█';
          animation: cursor 1.1s infinite step-start;
        }
        @keyframes cursor {
          50% {
            opacity: 0;
          }
        }
        .markdown-body {
          background-color: transparent !important;
          color: inherit !important;
        }
        .markdown-body a {
          text-decoration: underline;
        }
        .markdown-body pre {
          background-color: #1e1e1e !important;
        }
      `}</style>
      <br />
      <Prompt sessionId={sessionId} splashLoading={splashLoading} />
    </div>
  )
}

export default STTerm
